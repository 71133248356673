import { useFormik } from 'formik';
import { Parser } from 'html-to-react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';

import { EventModel, LanguageModel, LectureTypeModel } from '../../../models';
import {
  addReservation,
  getEventById,
  useLanguages,
  useLectureTypes
} from '../../../services';
import { mapToReservationModel } from '../../../utils';
import { BackButton, HtmlDbTooltip } from '../../components';

export const nameRegexp = /^([a-zA-ZÀ-ÖÙÉ-öùßé-ÿĀ-žḀ-ỿ0-9\-\040]|\.\040)+$/;
export const phoneRegexp = /^[0-9\s\-\/.]+$/;

export function EventRegister() {
  const { t, i18n } = useTranslation();
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<EventModel>();
  const [error, setError] = useState<any>(undefined);
  const [selectedLectureType, setSelectedLectureType] = useState<
    LectureTypeModel | undefined
  >(undefined);
  const [disableDoubleClick, setDisableDoubleClick] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getEventById(eventId ?? '')
      .then((response) => {
        setEvent(response);
        setSelectedLectureType(response?.lecture);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [eventId]);
  const languagesQuery = useLanguages();
  const lectureTypesQuery = useLectureTypes();

  const addReservationValidationSchema = yup.object().shape({
    firstname: yup
      .string()
      .min(1, t('eventRegistration.validationText.stringTooShort') ?? undefined)
      .max(
        255,
        t('eventRegistration.validationText.stringTooLongMax255') ?? undefined
      )
      .matches(
        nameRegexp,
        t('eventRegistration.validationText.invalidFirstname')
      )
      .required(t('eventRegistration.validationText.required') ?? undefined),
    lastname: yup
      .string()
      .min(1, t('eventRegistration.validationText.stringTooShort') ?? undefined)
      .max(
        255,
        t('eventRegistration.validationText.stringTooLongMax255') ?? undefined
      )
      .matches(
        nameRegexp,
        t('eventRegistration.validationText.invalidLastname')
      )
      .required(t('eventRegistration.validationText.required') ?? undefined),
    email: yup
      .string()
      .email(t('eventRegistration.validationText.invalidEmail') ?? undefined)
      .max(
        255,
        t('eventRegistration.validationText.stringTooLongMax255') ?? undefined
      )
      .required(t('eventRegistration.validationText.required') ?? undefined),
    numberOfGuests: yup
      .number()
      .typeError(t('eventRegistration.validationText.number') ?? '')
      .min(1, t('eventRegistration.validationText.positiveNumber') ?? undefined)
      .integer(t('eventRegistration.validationText.integer') ?? undefined)
      .required(t('eventRegistration.validationText.required') ?? undefined),
    phone: yup
      .string()
      .min(1, t('eventRegistration.validationText.stringTooShort') ?? undefined)
      .max(
        40,
        t('eventRegistration.validationText.stringTooLongMax40') ?? undefined
      )
      .matches(phoneRegexp, t('eventRegistration.validationText.invalidPhone')),
    personalId: yup.string(),
    educationalPurpose: yup
      .boolean()
      .required(t('eventRegistration.validationText.required') ?? undefined),
    languageId: yup
      .number()
      .typeError(t('eventRegistration.validationText.number') ?? '')
      .integer(t('eventRegistration.validationText.integer') ?? undefined)
      .min(
        0,
        t('eventRegistration.validationText.positiveNumber') ?? undefined
      ),
    lectureTypeId: yup
      .number()
      .typeError(t('eventRegistration.validationText.number') ?? '')
      .integer(t('eventRegistration.validationText.integer') ?? undefined)
      .min(
        0,
        t('eventRegistration.validationText.positiveNumber') ?? undefined
      ),
    termsAccepted: yup
      .boolean()
      .required(t('eventRegistration.validationText.required') ?? undefined)
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      numberOfGuests: 1,
      phone: '',
      personalId: '',
      educationalPurpose: false,
      languageId: event?.language?.id ?? '',
      lectureTypeId: event?.lecture?.id ?? '',
      termsAccepted: false
    },
    enableReinitialize: true,
    validationSchema: addReservationValidationSchema,
    onSubmit: (values) => {
      setDisableDoubleClick(true);
      addReservation(mapToReservationModel(values, event))
        .then(() => {
          setLoading(true);
          navigate('/event/' + eventId);
        })
        .catch((error) => setError(error))
        .finally(() => {
          setLoading(false);
          setDisableDoubleClick(false);
        });
    }
  });

  const showResponseErrorMessage = (response: string): string => {
    switch (response) {
      case 'Not enough seats.':
        return t('eventRegistration.validationText.notEnoughSeats');
      case 'The event is no more available.':
        return t('eventRegistration.validationText.notAvailableEvent');
      case 'You have reached the maximum number of reservations permitted for this event!':
        return t('eventRegistration.validationText.maxReservationsReached');
      case 'Please wait a couple of minutes before making another reservation!':
        return t('eventRegistration.validationText.nextReservationTimeLimit');
      default:
        return t('common.unknownErrorOccurred');
    }
  };

  return (
    <Stack spacing={2} sx={{ p: 3, height: '100%' }}>
      <Stack sx={{ overflow: 'auto' }}>
        <BackButton to={'/event/' + eventId} />

        <Typography
          variant="h3"
          component="h2"
          data-testid="pageTitle"
          sx={{ paddingTop: '1.5rem' }}
        >
          {t('eventRegistration.title')}
        </Typography>

        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ paddingTop: 5, width: '100%' }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="space-between"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {t('eventRegistration.event')}
              </Typography>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {i18n.language === 'en'
                  ? event?.eventType.nameInEnglish
                  : event?.eventType.nameInGerman}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {t('eventRegistration.start')}
              </Typography>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {(event?.startDate ?? '') +
                  ' ' +
                  (event?.startTime ?? '') +
                  ' ' +
                  t('common.cet')}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {t('eventRegistration.end')}
              </Typography>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {(event?.endDate ?? '') +
                  ' ' +
                  (event?.endTime ?? '') +
                  ' ' +
                  t('common.cet')}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="space-between"
            spacing={2}
            sx={{ width: '100%', paddingTop: '1.5rem' }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {t('eventRegistration.availableSeats')}
              </Typography>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {event?.availableSeats}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {t('eventRegistration.location')}
              </Typography>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {event?.location?.street +
                  ' ' +
                  event?.location?.houseNumber +
                  ', ' +
                  event?.location?.postCode +
                  ' ' +
                  event?.location?.city}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {t('eventRegistration.price')}
              </Typography>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {(event?.eventType.price ?? 0) > 0
                  ? event?.eventType.price + ' €'
                  : t('eventRegistration.free')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Paper sx={{ padding: '2rem', width: '100%' }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              spacing={{ xs: 3, sm: 8 }}
              direction="column"
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              <Typography
                variant="h4"
                component="h2"
                data-testid="pageTitle"
                sx={{ paddingTop: '0.3rem' }}
              >
                {t('eventRegistration.personalData')}
              </Typography>

              {error && (
                <Alert severity="error">
                  {showResponseErrorMessage(error?.response?.data?.message)}
                </Alert>
              )}

              <TextField
                label={t('eventRegistration.addForm.firstnameLabel')}
                placeholder={
                  t('eventRegistration.addForm.firstnamePlaceholder') ?? ''
                }
                id="firstname"
                name="firstname"
                sx={{ width: '100%' }}
                value={formik.values.firstname}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.firstname && formik.errors.firstname
                )}
                helperText={formik.touched.firstname && formik.errors.firstname}
                required
              />

              <TextField
                label={t('eventRegistration.addForm.lastnameLabel')}
                placeholder={
                  t('eventRegistration.addForm.lastnamePlaceholder') ?? ''
                }
                id="lastname"
                name="lastname"
                sx={{ width: '100%' }}
                value={formik.values.lastname}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.lastname && formik.errors.lastname
                )}
                helperText={formik.touched.lastname && formik.errors.lastname}
                required
              />
              <TextField
                label={t('eventRegistration.addForm.emailLabel')}
                placeholder={
                  t('eventRegistration.addForm.emailPlaceholder') ?? ''
                }
                id="email"
                name="email"
                sx={{ width: '100%' }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
              />

              <TextField
                id="numberOfGuests"
                name="numberOfGuests"
                label={t('eventRegistration.addForm.numberOfGuestsLabel')}
                placeholder={
                  t('eventRegistration.addForm.numberOfGuestsPlaceholder') ?? ''
                }
                type="number"
                sx={{ width: '100%' }}
                value={formik.values.numberOfGuests}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.numberOfGuests && formik.errors.numberOfGuests
                )}
                helperText={
                  formik.touched.numberOfGuests && formik.errors.numberOfGuests
                }
                required
              />

              <TextField
                label={t('eventRegistration.addForm.phoneLabel')}
                placeholder={
                  t('eventRegistration.addForm.phonePlaceholder') ?? ''
                }
                id="phone"
                name="phone"
                sx={{ width: '100%' }}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.phone && formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />

              <TextField
                label="Personal-ID"
                placeholder="Personal-ID"
                id="personalId"
                name="personalId"
                className="personalId"
                sx={{ width: '50%' }}
                value={formik.values.personalId}
                onChange={formik.handleChange}
                inputProps={{ className: 'personalId' }}
                role="textbox"
                error={Boolean(
                  formik.touched.personalId && formik.errors.personalId
                )}
                helperText={
                  formik.touched.personalId && formik.errors.personalId
                }
              />

              {(event?.eventType?.includingLecture ?? false) &&
                (languagesQuery.isLoading || languagesQuery.isFetching ? (
                  <CircularProgress />
                ) : (
                  <TextField
                    select
                    label={t('eventRegistration.addForm.languageLabel')}
                    placeholder={
                      t('eventRegistration.addForm.LanguagePlaceholder') ?? ''
                    }
                    id="languageId"
                    name="languageId"
                    sx={{ width: '100%' }}
                    value={formik.values.languageId}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.languageId && formik.errors.languageId
                    )}
                    helperText={
                      formik.touched.languageId && formik.errors.languageId
                    }
                    disabled={!!event?.language}
                  >
                    <MenuItem value="">-</MenuItem>
                    {languagesQuery?.data?.map((menuItem: LanguageModel) => (
                      <MenuItem key={menuItem.id} value={menuItem.id}>
                        {i18n.language === 'en'
                          ? menuItem.nameInEnglish
                          : menuItem.nameInGerman}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}

              {(event?.eventType?.includingLecture ?? false) &&
                (lectureTypesQuery.isLoading || lectureTypesQuery.isFetching ? (
                  <CircularProgress />
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ width: '100%' }}
                  >
                    <TextField
                      select
                      label={t('eventRegistration.addForm.lectureTypeLabel')}
                      placeholder={
                        t('eventRegistration.addForm.lectureTypePlaceholder') ??
                        ''
                      }
                      id="lectureTypeId"
                      name="lectureTypeId"
                      sx={{ width: '80%' }}
                      value={formik.values.lectureTypeId}
                      onChange={(changedEvent) => {
                        formik.handleChange(changedEvent);
                        const selectedId = parseInt(
                          changedEvent.target.value ?? '-1'
                        );
                        const selectedLecture = lectureTypesQuery?.data?.find(
                          (lecture) => lecture.id === selectedId
                        );
                        setSelectedLectureType(selectedLecture);
                      }}
                      error={Boolean(
                        formik.touched.lectureTypeId &&
                          formik.errors.lectureTypeId
                      )}
                      helperText={
                        formik.touched.lectureTypeId &&
                        formik.errors.lectureTypeId
                      }
                      disabled={!!event?.lecture}
                    >
                      <MenuItem value="">-</MenuItem>
                      {lectureTypesQuery?.data?.map(
                        (menuItem: LectureTypeModel) => (
                          <MenuItem key={menuItem.id} value={menuItem.id}>
                            {i18n.language === 'en'
                              ? menuItem.nameInEnglish
                              : menuItem.nameInGerman}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                    {selectedLectureType && (
                      <HtmlDbTooltip
                        title={
                          i18n.language === 'en'
                            ? selectedLectureType?.nameInEnglish
                            : selectedLectureType?.nameInGerman
                        }
                        body={
                          i18n.language === 'en'
                            ? Parser().parse(
                                selectedLectureType?.descriptionInEnglish
                              )
                            : Parser().parse(
                                selectedLectureType?.descriptionInGerman
                              )
                        }
                      />
                    )}
                  </Stack>
                ))}

              <Typography variant="body2">
                {t('eventRegistration.addForm.requiredExplanationText')}
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    id="educationalPurpose"
                    name="educationalPurpose"
                    value={formik.values.educationalPurpose}
                    checked={formik.values.educationalPurpose}
                    onChange={formik.handleChange}
                  />
                }
                label={t('eventRegistration.addForm.educationalPurposeLabel')}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="termsAccepted"
                    name="termsAccepted"
                    checked={formik.values.termsAccepted}
                    value={formik.values.educationalPurpose}
                    onChange={formik.handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ paddingTop: 0 }}
                  />
                }
                label={
                  <Typography variant="body2">
                    <Trans
                      i18nKey="eventRegistration.addForm.terms"
                      transSupportBasicHtmlNodes={true}
                      components={{ a: <a /> }}
                      values={{ baseUrl: process.env.PUBLIC_URL }}
                    ></Trans>
                  </Typography>
                }
                sx={{ alignItems: 'flex-start' }}
              />

              <Stack
                spacing={{ xs: 1, sm: 4 }}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={
                    loading ||
                    !formik.values.termsAccepted ||
                    disableDoubleClick
                  }
                >
                  <Typography variant="h5">
                    {t('eventRegistration.addForm.register')}
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </form>
        </Paper>
      </Stack>
    </Stack>
  );
}
